import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const BannerCTA = memo(function BannerCTA({ items }: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Background />
      <Wrapper>
        {items.map((item, index) => (
          <Item
            key={index}
            variant={index === 0 ? 'default' : 'second'}
            {...item}
          />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin: 14.0625rem 0 12.1875rem;

  @media (max-width: 1199px) {
    margin: 5rem 0 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`

const Background = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50%);
  transform: translate(-50%, -50%);
  width: 82.125vw;
  max-width: 82.125rem;
  height: 29.375rem;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 1199px) {
    display: none;
  }
`
