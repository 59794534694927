import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { styles } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import Masonry from 'react-masonry-component'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const HistoryBlock = memo(function HistoryBlock({
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  const elemsLeft = [0, 3, 5, 6, 9, 11]
  const elemsRight = [1, 2, 4, 7, 8, 10]

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}

          <Media greaterThanOrEqual="tabletMedium">
            <WrapperTimeline>
              <Left>
                {items.map((item, index) =>
                  elemsLeft.includes(index) ? (
                    <Item key={index} {...item} />
                  ) : null,
                )}
              </Left>
              <Right>
                {items.map((item, index) =>
                  elemsRight.includes(index) ? (
                    <Item key={index} {...item} />
                  ) : null,
                )}
              </Right>
            </WrapperTimeline>
          </Media>
          <Media lessThan="tabletMedium">
            {items.map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.25rem 0;
  }
  @media (max-width: 992px) {
    margin: 4.375rem 1.25rem 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const WrapperTimeline = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-top: -8.9375rem;
  margin-left: 8.9375rem;

  /*   @media (min-width: 993px) {
    > div:first-of-type {
      margin-top: 6.25rem;
    }
  }
  @media (min-width: 1200px) {
    > div:first-of-type {
      margin-top: 12.5rem;
    }
  } */

  @media (max-width: 1600px) {
    margin-left: 8.938vw;
  }

  @media (max-width: 1199px) {
    margin-left: 6.938vw;
  }

  @media (max-width: 992px) {
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0rem;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const Title = styled.h2`
  ${styles.h2}
  width: 50%;

  @media (max-width: 992px) {
    width: 100%;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`
const MasonryContainer = styled(Masonry)<MasonryProps>`
  width: 100%;
  margin-left: -1rem;
`
const Items = styled.div`
  width: 100%;
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
